<template>
  <div class="speciality">
    <p class="speciality__name">{{ speciality.name }}</p>
    <div class="speciality__menu">
      <div
          class="speciality__menu-icon"
          :class="{'speciality__menu-icon_active': activeMenu === speciality.id}"
          :id="speciality.id"
          @click="openMenu(speciality.id)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#858585" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
              stroke="#858585" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              stroke="#858585" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="speciality__menu-block" v-if="activeMenu === speciality.id">
        <div class="speciality__menu-btn-wrapper">
          <div class="speciality__menu-btn speciality__menu-btn_edit"
               @click="$emit('openEditSpecialtyPopup', speciality)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.6083 3.54373L17.7696 3.38247C17.8909 3.26116 18.035 3.16495 18.1936 3.09932C18.3521 3.0337 18.5221 2.99995 18.6937 3C18.8653 3.00005 19.0352 3.0339 19.1937 3.09962C19.3522 3.16533 19.4962 3.26163 19.6175 3.38301C19.7388 3.50439 19.8351 3.64848 19.9007 3.80704C19.9663 3.9656 20 4.13554 20 4.30714C20 4.47875 19.9661 4.64866 19.9004 4.80719C19.8347 4.96571 19.7384 5.10974 19.617 5.23105L19.4557 5.39123C19.7316 5.70062 19.8787 6.10381 19.8668 6.51816C19.8549 6.9325 19.685 7.32661 19.3919 7.6197L8.25315 18.7599C8.18352 18.8291 8.09634 18.8782 8.00098 18.9017L3.67189 19.984C3.5813 20.0065 3.48641 20.0053 3.39645 19.9803C3.30648 19.9553 3.22451 19.9075 3.1585 19.8415C3.09248 19.7755 3.04467 19.6935 3.0197 19.6035C2.99474 19.5136 2.99348 19.4187 3.01603 19.3281L4.09831 14.9989C4.12199 14.9039 4.17099 14.8171 4.24008 14.7478L14.6753 4.31217C14.571 4.23872 14.444 4.20451 14.3169 4.2156C14.1897 4.22669 14.0706 4.28236 13.9805 4.37278L10.4177 7.93682C10.3674 7.98713 10.3076 8.02704 10.2419 8.05427C10.1762 8.0815 10.1057 8.09552 10.0346 8.09552C9.96341 8.09552 9.89296 8.0815 9.82722 8.05427C9.76148 8.02704 9.70176 7.98713 9.65144 7.93682C9.60113 7.8865 9.56122 7.82677 9.53399 7.76103C9.50676 7.69529 9.49275 7.62483 9.49275 7.55368C9.49275 7.48252 9.50676 7.41207 9.53399 7.34633C9.56122 7.28059 9.60113 7.22086 9.65144 7.17054L13.2164 3.60759C13.5097 3.3143 13.9041 3.14439 14.3187 3.13271C14.7333 3.12104 15.1366 3.26849 15.4459 3.54482C15.7433 3.27904 16.1281 3.13204 16.5269 3.13184C16.9257 3.13164 17.3107 3.27826 17.6083 3.54373ZM16.9113 4.37278C16.8098 4.27133 16.6722 4.21434 16.5287 4.21434C16.3852 4.21434 16.2476 4.27133 16.1462 4.37278L5.11023 15.4069L4.28338 18.7155L7.59188 17.8886L18.6278 6.85451C18.6782 6.80424 18.7182 6.74452 18.7455 6.67878C18.7727 6.61303 18.7868 6.54255 18.7868 6.47137C18.7868 6.40019 18.7727 6.32971 18.7455 6.26397C18.7182 6.19822 18.6782 6.1385 18.6278 6.08824L16.9124 4.37278H16.9113Z" fill="#0086B2"/>
            </svg>


            <p class="speciality__menu-btn-name">Редактировать</p>
          </div>
        </div>
        <div class="speciality__menu-btn-wrapper">
          <div class="speciality__menu-btn speciality__menu-btn_delete"
               @click="deleteSpecialty">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 7.33398H6.55556H19" stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.4446 7.33377V18.2227C17.4446 18.6352 17.2807 19.0309 16.9889 19.3226C16.6972 19.6143 16.3016 19.7782 15.889 19.7782H8.11122C7.69866 19.7782 7.303 19.6143 7.01128 19.3226C6.71955 19.0309 6.55566 18.6352 6.55566 18.2227V7.33377M8.889 7.33377V5.77821C8.889 5.36565 9.05289 4.96999 9.34461 4.67827C9.63633 4.38654 10.032 4.22266 10.4446 4.22266H13.5557C13.9682 4.22266 14.3639 4.38654 14.6556 4.67827C14.9473 4.96999 15.1112 5.36565 15.1112 5.77821V7.33377" stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>


            <p class="speciality__menu-btn-name">Удалить</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
  speciality: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["deleteSpecialty", "prohibitDelete"]);

const activeMenu = ref(null)
const openMenu = (id) => activeMenu.value = activeMenu.value === id ? null : id

document.addEventListener('click', (e) => {
  const parent = e.target.closest('.speciality__menu-icon')

  if (parent && Number(parent.id) === props.speciality.id) return
  activeMenu.value = null
})

const deleteSpecialty = () => {
  if (props.speciality.can_delete) {
    emit('deleteSpecialty', props.speciality.id)
  } else {
    emit('prohibitDelete')
  }
}
</script>

<style scoped lang="scss">
.speciality {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;

  &__name {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  &__menu {
    position: relative;
  }

  &__menu-icon {
    cursor: pointer;

    svg:hover path {
      stroke: #000;
    }

    &_active svg path {
      stroke: #000;
    }
  }

  &__menu-block {
    position: absolute;
    top: 27px;
    left: -165px;
    width: 191px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.12);
    border: 1px solid $light-gray-1;
    z-index: 2;
  }

  &__menu-btn {
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $light;
    }

    &:active {
      background-color: $light-gray-1;
    }

    &_edit {
      color: $blue;
    }

    &_delete {
      margin-top: 8px;
      color: $red;
    }
  }

  &__menu-btn-name {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
